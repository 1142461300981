// Customizable Area Start
import { Theme, makeStyles } from "@material-ui/core";

export const Appheader = makeStyles((theme: Theme) => ({
  menuIcon: {
    padding: theme.spacing(0),
    "& svg": {
      color: "#222222"
    }
  },
  drawer: {
    width: "260px"
  },
  drawerCloseButton: {
    margin: theme.spacing(1.25),
    fontSize: "32px",
    color: "#364F6B"
  },
  divider: {
    margin: theme.spacing(0, 2)
  },
  appbar: {
    zIndex: 1,
    boxShadow: "none",
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    minHeight: "76px",
    padding: "0 80px",
    backgroundColor: "#FFF",
    [theme.breakpoints.down(1200)]: {
      padding: "0 40px",
    },
    [theme.breakpoints.down(1150)]: {
      padding: "0 18px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0 18px",
      minHeight: "56px"
    }
  },
  leftSideContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    }
  },
  homeLink: {
    textDecoration: "none",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    height: '100%',
    marginRight: "20px"
  },
  logo: {
    maxWidth: "140px !important",
    objectFit: "contain",
    marginRight: theme.spacing(1),
    borderRadius: "5px",
    height: '100%',
    [theme.breakpoints.down("sm")]: {
      margin: theme.spacing(0, 1, 0, 1.5)
    }
  },
  logoAppHeader: {
    maxWidth: "310px",
    height: "60px",
    [theme.breakpoints.down("sm")]: {
      height: "40px",
      maxWidth: "100%",
      display: "flex",
      justifyContent: "center",
      width: "50%"
    }
  },
  title: {
    fontSize: "20px",
    fontWeight: 700,
    lineHeight: "initial",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
      marginRight: "0 !important",
      lineHeight: "initial"
    }
  },
  logoNavigationContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '45%',
    [theme.breakpoints.down("sm")]: {
      width: '100%',
    },
  },
  navigationItems: {
    display: "flex",
    gap: theme.spacing(5),
    [theme.breakpoints.down(1050)]: {
      gap: "25px"
    },
  },
  link: {
    textDecoration: "none",
    [theme.breakpoints.down("sm")]: {
      flex: 1
    },
    cursor: 'pointer'
  },
  linkText: {
    fontSize: "18px",
    lineHeight: "24px",
    fontWeight: 600,
    color: "var(--website-builder-secondary-color) !important",
    fontFamily: "var(--website-builder-body-font)",
    whiteSpace: "nowrap",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      fontWeight: 600,
      letterSpacing: "0.14px",
      color: "#364F6B",
      padding: theme.spacing(1.5, 0)
    }
  },
  RightSideContainer: {
    display: "flex",
    gap: '20px',
    width: "45%",
    justifyContent: "end"
  },
  inputAdornment: {
    marginRight: theme.spacing(1.5)
  },
  searchBarIconAdornment: {
    paddingLeft: theme.spacing(1),
    borderRadius: "8px",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: theme.spacing(0)
    }
  },
  searchBar: {
    height: "38px",
    width: "224px",
    backgroundColor: "#fff",
    padding: theme.spacing(0, 1.75, 0, 0),
    fontSize: "14px",
    lineHeight: "24px",
    [theme.breakpoints.down(1100)]: {
      width: "180px"
    },
    [theme.breakpoints.down(1050)]: {
      width: "140px"
    },
    [theme.breakpoints.down("sm")]: {
      width: "262px",
      "& + fieldset": {
        border: "none"
      }
    }
  },
  searchIcon: {
    color: "#757575"
  },
  closeIcon: {
    color: "#262626",
    fontSize: "17px"
  },
  bookNowButton: {
    display: "flex",
    justifyContent: "center",
    flex: 1,
    backgroundColor: "var(--website-builder-primary-color)",
    fontFamily: "var(--website-builder-body-font)",
    fontSize: "14px",
    fontWeight: 700,
    padding: "8px 24px",
    lineHeight: "20px",
    borderRadius: "8px",
    textDecoration: "none",
    whiteSpace: 'nowrap',
    color: "#fff",
    [theme.breakpoints.down(1050)]: {
      padding: "8px 15px"
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(1.5)
    }
  },
  skeletonBox:{
    width: "160px",
    height: "35px"
  },
  searchbarWrapperDiv: {
    position:"relative",
    [theme.breakpoints.down(960)]: {
      width: "100%"
    },
  },
  serarchListSuggestion: {
    position: "absolute",
    zIndex: 9,
    backgroundColor: "white",
    width: "100%",
    maxHeight: "180px",
    overflowY: "scroll",
    borderBottomLeftRadius: "10px",
    borderBottomRightRadius: "10px",
    scrollbarWidth: "thin",
    display:"block"
  },
  serarchListSuggestionHide:{
    display:"none"
  },
  closeBox:{
    width:"100%",
    height:"100%",
    position:"fixed",
    zIndex:1,
    left:"0",
    top:"0"
  },
  titleName: {
    lineHeight: "35px",
    paddingLeft: "15px",
    color: "black",
    "&:hover": {
      backgroundColor: "#f0f0f0",
      borderRadius: "5px",
    }
  },
  boldText: {
    fontWeight: 600,
    color: "var(--website-builder-secondary-color)",
    fontFamily: "var(--website-builder-body-font)"
  },
  normalText: {
    fontWeight: 400,
    color: "var(--website-builder-secondary-color)",
    fontFamily: "var(--website-builder-body-font)"
  },
  manageBookingModalContainer: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '600px',
    backgroundColor: '#fff',
    borderRadius: "10px",
    boxShadow: '0px 4px 4px 0px #F5F5F5',
    [theme.breakpoints.down("xs")]: {
      transform: 'none',
      top: 0,
      width: '100%',
      height: '100%',
      left: 0,
      borderRadius: "0",
    }
  },
  modalHeader: {
    fontSize: '16px',
    fontWeight: 400,
    textAlign: 'center',
    padding: theme.spacing(2.5),
    position: "relative",
    borderBottom: '1px solid #E8E8E8',
    color: "var(--website-builder-secondary-color)",
    fontFamily: "var(--website-builder-header-font) !important",
    "& .MuiTypography-body1":{
      color: "var(--website-builder-secondary-color)",
      fontFamily: "var(--website-builder-header-font) !important",
    }
  },
  crossIcon: {
    position: "absolute",
    right: 20,
    top: "25px",
    cursor: 'pointer'
  },
  modalSubHeader: {
    fontSize: '17px',
    fontWeight: 400,
    color: "var(--website-builder-secondary-color)",
    fontFamily: "var(--website-builder-body-font)"
  },
  subContainer: {
    padding: "20px 40px 40px 40px",
    [theme.breakpoints.down("xs")]: {
      padding: "20px"
    }
  },
  inputlabel: {
    fontSize: '12px',
    fontWeight: 500,
    marginTop: '20px',
    color: "var(--website-builder-secondary-color)",
    fontFamily: "var(--website-builder-body-font)"
  },
  inputField: {
    marginTop: '4px',
    "& .MuiOutlinedInput-input": {
      padding: '14px'
    }
  },
  searchBookingButton: {
    display: "flex",
    justifyContent: "center",
    flex: 1,
    color: "#FFFFFF",
    backgroundColor: "var(--website-builder-primary-color)",
    fontFamily: "var(--website-builder-body-font)",
    fontSize: "16px",
    fontWeight: 500,
    padding: theme.spacing(1.5),
    borderRadius: "3px",
    textDecoration: "none",
    marginTop: '20px'
  },
  manageBookingModal: {
    [theme.breakpoints.down("xs")]: {
      height: "calc(100vh - 58px)",
      top: "58px !important"
    }
  }
}));
// Customizable Area End